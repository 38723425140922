import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LoginPage from './views/Security/LoginPage';
import ReportPage from './views/Report/ReportPage';
import RegistrationPage from './views/Security/RegistrationPage';
import RouteManagementPage from './views/RouteManagement/RouteManagementPage';
// import PrivateRoute from './PrivateRoute';
import Dashboard from './views/Security/Dashboard';
import RouteRevenueReport from './components/Report/RouteRevenueReport';
import ConsumptionReport from './components/Report/ConsumptionReport';
import MerchantRevenueReport from './components/Report/MerchantRevenueReport';
import BusRevenueReport from './components/Report/BusRevenueReport';
import LogViewer from './components/logViewer'; // Ensure this is correctly imported


function App() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegistrationPage />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/RouteRevenueReport" element={<RouteRevenueReport/>} />
      <Route path="/ConsumptionReport" element={<ConsumptionReport/>} />
      <Route path="/MerchantRevenueReport" element={<MerchantRevenueReport/>} />
      <Route path="/BusRevenueReport" element={<BusRevenueReport/>} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/LogViewer" element={<LogViewer />} /> {/* Define the correct path */}
      {/* Add other routes as needed */}
    </Routes>
  );
};

export default App;

