import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Table, Breadcrumb, Pagination } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import * as XLSX from 'xlsx';
import { FaUserCheck, FaExclamationTriangle, FaBug, FaFilter, FaDownload, FaPrint } from 'react-icons/fa';

const LogViewer = () => {
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [filter, setFilter] = useState({ eventType: '', userId: '', dateRange: { start: '', end: '' } });

  useEffect(() => {
    // Mocked data for demonstration
    const mockedLogs = [
      { timestamp: '2024-08-12T10:00:00Z', userId: 'user123', ipAddress: '192.168.1.1', eventType: 'login_attempt' },
      { timestamp: '2024-08-12T10:05:00Z', userId: 'user456', ipAddress: '192.168.1.2', eventType: 'button_click', fileName: 'report1.xlsx' },
      { timestamp: '2024-08-12T10:10:00Z', userId: 'user123', ipAddress: '192.168.1.1', eventType: 'button_click', fileName: 'report2.xlsx' },
    ];
    setLogs(mockedLogs);
    setFilteredLogs(mockedLogs);
    generateChartData(mockedLogs);
  }, []);

  const generateChartData = (logs) => {
    if (!logs.length) return;

    const loginAttempts = logs.filter(log => log.eventType === 'login_attempt').length;
    const buttonClicks = logs.filter(log => log.eventType === 'button_click').length;

    setChartData({
      labels: ['Login Attempts', 'Button Clicks'],
      datasets: [
        {
          label: 'Number of Events',
          data: [loginAttempts, buttonClicks],
          backgroundColor: ['#4CAF50', '#FF6384'],
        },
      ],
    });
  };

  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(filteredLogs);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Logs');
    XLSX.writeFile(wb, 'logs_report.xlsx');
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const applyFilters = () => {
    let updatedLogs = [...logs];

    if (filter.eventType) {
      updatedLogs = updatedLogs.filter(log => log.eventType === filter.eventType);
    }
    if (filter.userId) {
      updatedLogs = updatedLogs.filter(log => log.userId === filter.userId);
    }
    if (filter.dateRange.start && filter.dateRange.end) {
      updatedLogs = updatedLogs.filter(log => {
        const logDate = new Date(log.timestamp);
        return logDate >= new Date(filter.dateRange.start) && logDate <= new Date(filter.dateRange.end);
      });
    }

    setFilteredLogs(updatedLogs);
    generateChartData(updatedLogs);
  };

  return (
    <Container fluid className="p-4 bg-light">
      {/* Header Section */}
      <Row className="align-items-center mb-4">
        <Col>
          <h1 className="h3 mb-0 text-dark">System Logs</h1>
          <Breadcrumb>
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Logs</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      {/* Summary Statistics Cards */}
      <Row className="mb-4">
        <Col md={4}>
          <Card className="text-center border-0 shadow-sm">
            <Card.Body>
              <FaUserCheck className="mb-2 text-primary" size={40} />
              <h4>Total Logins</h4>
              <p className="lead">1,234</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="text-center border-0 shadow-sm">
            <Card.Body>
              <FaExclamationTriangle className="mb-2 text-warning" size={40} />
              <h4>Warnings</h4>
              <p className="lead">56</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="text-center border-0 shadow-sm">
            <Card.Body>
              <FaBug className="mb-2 text-danger" size={40} />
              <h4>Errors</h4>
              <p className="lead">12</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Filter Bar */}
      <Form className="mb-4">
        <Row>
          <Col md={4}>
            <Form.Group controlId="eventType">
              <Form.Label>Event Type</Form.Label>
              <Form.Control as="select" name="eventType" onChange={handleFilterChange}>
                <option value="">All</option>
                <option value="login">Login</option>
                <option value="error">Error</option>
                <option value="warning">Warning</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="userId">
              <Form.Label>User ID</Form.Label>
              <Form.Control type="text" name="userId" placeholder="Enter User ID" onChange={handleFilterChange} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="dateRange">
              <Form.Label>Date Range</Form.Label>
              <Form.Control type="date" name="dateRange.start" onChange={handleFilterChange} />
            </Form.Group>
            <Form.Group className="mt-2" controlId="dateRangeEnd">
              <Form.Control type="date" name="dateRange.end" onChange={handleFilterChange} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12} className="text-right">
            <Button variant="primary" className="me-2" onClick={applyFilters}>
              <FaFilter className="me-1" /> Apply Filters
            </Button>
            <Button variant="success" className="me-2" onClick={handleExport}>
              <FaDownload className="me-1" /> Export to Excel
            </Button>
            <Button variant="secondary">
              <FaPrint className="me-1" /> Print
            </Button>
          </Col>
        </Row>
      </Form>

      {/* Log Summary Section */}
      <Row className="mb-4">
        <Col md={6}>
          <Card>
            <Card.Body>
              <h2 className="mb-4">Event Overview</h2>
              <Bar data={chartData} options={{ responsive: true }} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Detailed Logs Table */}
      <Card>
        <Card.Body>
          <h2 className="mb-4">Detailed Logs</h2>
          <Table striped bordered hover responsive className="shadow-sm">
            <thead className="table-dark">
              <tr>
                <th>Timestamp</th>
                <th>User ID</th>
                <th>IP Address</th>
                <th>Event Type</th>
                <th>File Name</th>
              </tr>
            </thead>
            <tbody>
              {filteredLogs.map((log, index) => (
                <tr key={index}>
                  <td>{new Date(log.timestamp).toLocaleString()}</td>
                  <td>{log.userId}</td>
                  <td>{log.ipAddress}</td>
                  <td>{log.eventType}</td>
                  <td>{log.fileName || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* Pagination Component */}
          <Pagination className="justify-content-end">
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Item>{2}</Pagination.Item>
            <Pagination.Item>{3}</Pagination.Item>
            <Pagination.Next />
          </Pagination>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default LogViewer;
