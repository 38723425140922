// RegistrationPage.js

import React, { useState } from 'react';
import AuthenticationService from '../../services/AuthenticationService';
import '../../styles/Report.css';

import logoImage2 from '../../img/tns.png';

// Import CSS files
import '../../vendor/bootstrap/css/bootstrap.min.css';
import '../../vendor/bootstrap-icons/bootstrap-icons.css';
import '../../vendor/boxicons/css/boxicons.min.css';
import '../../vendor/quill/quill.snow.css';
import '../../vendor/quill/quill.bubble.css';
import '../../vendor/remixicon/remixicon.css';
import '../../vendor/simple-datatables/style.css';

const RegistrationPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);

  const handleRegistration = async (e) => {
    e.preventDefault();
    setError(null);

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      await AuthenticationService.register(username, password);
      // Redirect to login page after successful registration
      // You might want to navigate the user to a different route or show a confirmation message
      alert('Registration successful. Please login.');
    } catch (error) {
      setError('Registration failed. Please try again.');
    }
  };

  return (
    <div className="container">
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div className="d-flex justify-content-center py-4">
                <a href="index.html" className="logo d-flex align-items-center w-auto">
                <img src={logoImage2} alt="tns" style= {{ width: '60px' ,height: '120px'}}/>
                </a>
              </div>
              <div className="card mb-3">
                <div className="card-body">
                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">Create an Account</h5>
                    <p className="text-center small">Enter your personal details to create an account</p>
                  </div>
                  <form onSubmit={handleRegistration} className="row g-3 needs-validation" noValidate>
                    <div className="col-12">
                      <label htmlFor="yourName" className="form-label">Your Name</label>
                      <input type="text" name="name" className="form-control" id="yourName" required />
                      <div className="invalid-feedback">Please enter your name!</div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="yourEmail" className="form-label">Your Email</label>
                      <input type="email" name="email" className="form-control" id="yourEmail" required />
                      <div className="invalid-feedback">Please enter a valid Email address!</div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="yourUsername" className="form-label">Username</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text" id="inputGroupPrepend">@</span>
                        <input type="text" name="username" className="form-control" id="yourUsername" required />
                        <div className="invalid-feedback">Please choose a username.</div>
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="yourPassword" className="form-label">Password</label>
                      <input type="password" name="password" className="form-control" id="yourPassword" required />
                      <div className="invalid-feedback">Please enter your password!</div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                      <input type="password" name="confirmPassword" className="form-control" id="confirmPassword" required />
                      <div className="invalid-feedback">Please confirm your password!</div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100" type="submit">Create Account</button>
                    </div>
                    <div className="col-12">
                      <p className="small mb-0">Already have an account? <a href="loginPage">Log in</a></p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RegistrationPage;
