import React, { useState, useEffect } from 'react'; // Import useEffect
import axios from 'axios';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';  
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faTimes, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import logoImage2 from '../../img/logo2.png';
import logoImage1 from '../../img/systemLogo.png';
import '../../styles/Report.css';
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';  // Import xlsx library
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { writeFile } from 'xlsx';

function BusRevenueReport() {
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState('');
  const [selectedRoute, setSelectedRoute] = useState('');
  const [selectedStatementConcept, setSelectedStatementConcept] = useState('');
  const [transType, setTransType] = useState('');
  const [dateType, setDateType] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [tchStatus, setTchStatus] = useState('');
  const [rejectReason, setRejectReason] = useState('');
  const [selectStatement, setSelectStatement] = useState('');
  const [queryResult, setQueryResult] = useState([]);
  const [tchStatuses, setTchStatuses] = useState([]);
  const [statementConcepts, setStatementConcepts] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [rows, setRows] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState('');
  let idCounter = 0;
  const rowsWithIds = rows.map(row => ({ ...row, id: idCounter++ }));

  // Fetch data from API URL when component mounts
  useEffect(() => {
    axios.get('https://transit-api-qa.thenakedscientists.co.za/api/Report/vehicle-revenue-report-filters')
      .then(response => {
        const { tchStatuses, statementConcepts, routes, merchants, vehicles } = response.data;
        console.log('TCH Statuses:', tchStatuses);
        console.log('Statement Concepts:', statementConcepts);
        console.log('Route Name:', routes);
        console.log('Merchants:', merchants);
        console.log('Vehicles:', vehicles);
        setTchStatuses(tchStatuses);
        setStatementConcepts(statementConcepts);
        setRoutes(routes);
        setMerchants(merchants);
        setVehicles(vehicles);
      })
      .catch(error => {
        console.error('Error fetching dropdown data:', error);
      });
  }, []);
    
// Columns for the DataGrid
const columns = [
  { field: 'id', headerName: 'ID', width: 150 },
  { field: 'route', headerName: 'Route', width: 150 },
  { field: 'merchantName', headerName: 'Merchant Name', width: 150 },
  { field: 'registration', headerName: 'Registration', width: 150 },
  { field: 'transactionQuantity', headerName: 'Transaction Quantity', width: 150 },
  { field: 'transactionTotalAmount', headerName: 'Transaction Total Amount', width: 150 },
  { field: 'receivingTime', headerName: 'Receiving Time', width: 305 },
  { field: 'station', headerName: 'Station', width: 305 },
  { field: 'merchantOrder', headerName: 'Merchant Order', width: 305 },
  { field: 'rejectReason', headerName: 'Reason for Reject', width: 305 },
  { field: 'transStatus', headerName: 'Trans Status', width: 305 },
];


  // Function to handle the form submission
  const handleQuery = () => {
    const busRevenueReport = {
      merchantId: selectedMerchant,
      rejectReason: "",
      tchStatus: tchStatus.toString(),
      tchStatementConcept: selectedStatementConcept,
      transactionDateFrom: fromDate,
      transactionDateTo: toDate,
      routeId: selectedRoute,
      vehicles: selectedVehicles,
    };

    axios.post('https://transit-api-qa.thenakedscientists.co.za/api/Report/vehicle-revenue-report', busRevenueReport, {
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        setRows(response.data);
        console.log('Data fetched successfully:', response.data);
      })
      .catch(error => {
        console.error('Error fetching DataGrid data:', error);
      });

    console.log('Filtered Rows:', busRevenueReport);
    setQueryResult(busRevenueReport);
  };


  // Function to handle form reset
  const handleReset = () => {
    setSelectedMerchant('');
    setSelectedRoute('');
    setSelectedStatementConcept('');
    setTransType('');
    setDateType('');
    setFromDate('');
    setToDate('');
    setTchStatus('');
    setRejectReason('');
    setSelectStatement('');
    setQueryResult([]);
    setSelectedVehicles([]);
  };

// Function to handle export to Excel
const handleExportToExcel = () => {
  // Generate the current date and time string in South African time
  const now = new Date();
  const options = {
    timeZone: 'Africa/Johannesburg',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };
  
  const formatter = new Intl.DateTimeFormat('en-GB', options);
  const [{ value: month }, , { value: day }, , { value: year }, , { value: hour }, , { value: minute }, , { value: second }] = formatter.formatToParts(now);
  
  const timestamp = `${year}-${month}-${day} ${hour}-${minute}-${second}`;

  // Create the worksheet and workbook
  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Consumption Report');

  // Generate the filename with timestamp
  const filename = `ConsumptionReport_${timestamp}.xlsx`;

  // Write the workbook to a file
  XLSX.writeFile(workbook, filename);
};

  // Function to handle export to PDF
  const handleExportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [columns.map(col => col.headerName)],
      body: rows.map(row => columns.map(col => row[col.field])),
    });
    doc.save('ConsumptionReport.pdf');
  };


  return (
    <>
      {/* ======= Header ======= */}
      <header id="header" className="header fixed-top d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between">
          <a href=" " className="logo d-flex align-items-center">
            <img src={logoImage1} alt="" />
            <span className="d-none d-lg-block">TNS Ticketing Transit</span>
          </a>
          <i className="bi bi-list toggle-sidebar-btn"></i>
        </div>
      </header>
      {/* End Header */}
      <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <a className="nav-link" href="dashboard#">
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </a>
        </li>
        <li className="nav-heading" style={{ fontWeight: "bold" }}>Reports</li>
        <li className="nav-item">
          <a className="nav-link" href="BusRevenueReport">
            <i className="bi bi-card-list"></i>
                  <span>Bus Revenue Report</span>
              </a>
            </li>
            <li className="nav-item">
          <a className="nav-link" href="ConsumptionReport">
            <i className="bi bi-card-list"></i>
                  <span>Consumption Report</span>
              </a>
            </li>
            <li className="nav-item">
          <a className="nav-link" href="MerchantRevenueReport">
            <i className="bi bi-card-list"></i>
                <span>Merchant Revenue Report</span>
              </a>
            </li>
            <li className="nav-item">
          <a className="nav-link" href="RouteRevenueReport">
            <i className="bi bi-card-list"></i>
                  <span>Route Revenue Report</span>
              </a>
          </li>

          <li className="nav-heading" style={{ fontWeight: "bold" }}>Route Management</li>
        <li className="nav-item">
          <a className="nav-link" href="CreateRoute">
            <i className="bi bi-card-list"></i>
                <span>Create Route</span>
              </a>
            </li>
            <li className="nav-item">
          <a className="nav-link" href="ListRoute">
            <i className="bi bi-card-list"></i>
                  <span>List Route</span>
              </a>
          </li>

      
          <li className="nav-heading" style={{ fontWeight: "bold" }}>Pages</li>
        <li className="nav-item">
          <a className="nav-link" href="Login">
            <i className="bi bi-box-arrow-in-right"></i>
            <span>Login</span>
          </a>
        </li>
      </ul>
    </aside>
      {/* Main Content */}
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Bus Revenue Report</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="dashboard#">Home</a></li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
          <div style={{ textAlign: 'center' }}>
            <img src={logoImage2} alt="Dashboard Logo" style={{ width: '320px', height: '130px' }} />
          </div>
        </div>

        <div className="header-spacing"></div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Select
                id="merchants"
                value={selectedMerchant} // Set the selected value here based on your application logic
                onChange={(e) => setSelectedMerchant(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Select Merchant</MenuItem>
                {merchants.map((merchant, index) => ( // Ensure that merchants is an array
                  <MenuItem key={index} value={merchant.id}>{merchant.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
            <Select
              id="routes"
              value={selectedRoute}
              onChange={(e) => setSelectedRoute(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">Select Route Name</MenuItem>
              {routes.map(route => (
                <MenuItem key={route.id} value={route.id}>{route.name}</MenuItem>
              ))}
            </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
            <Select
              id="vehicles"
              value={selectedVehicles}
              onChange={(e) => setSelectedVehicles(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">Plate Number</MenuItem>
              {vehicles.map(vehicles => (
                <MenuItem key={vehicles.id} value={vehicles.id}>{vehicles.registration}</MenuItem>
              ))}
            </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  id="fromDate"
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  fullWidth
                  variant="outlined"
                  label="From Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="toDate"
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  fullWidth
                  variant="outlined"
                  label="To Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Select
                id="tchStatus"
                value={tchStatus}
                onChange={(e) => setTchStatus(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Select Date Type</MenuItem>
                <MenuItem value="Status 1">Status 1</MenuItem>
                <MenuItem value="Status 2">Status 2</MenuItem>
                <MenuItem value="Status 3">Status 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
            <Select
                id="tchStatuses"
                value={tchStatus}
                onChange={(e) => setTchStatus(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Select TCH Status</MenuItem>
                <MenuItem value="ALL">ALL</MenuItem>
                {tchStatuses.map(status => (
                  <MenuItem key={status.key} value={status.key}>{status.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Select
                id="selectStatement"
                value={selectStatement}
                onChange={(e) => setSelectStatement(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Reject Reason</MenuItem>
                <MenuItem value="Statement 1">Statement 1</MenuItem>
                <MenuItem value="Statement 2">Statement 2</MenuItem>
                <MenuItem value="Statement 3">Statement 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
            <Select
              id="statementConcepts"
              value={selectedStatementConcept} // Assuming you have a state variable to store the selected statement concept
              onChange={(e) => setSelectedStatementConcept(e.target.value)} // Assuming you have a state setter function for the selected statement concept
              displayEmpty
            >
              <MenuItem value="">Statement Concept</MenuItem>
              {statementConcepts.map(concept => (
                <MenuItem key={concept.key} value={concept.key}>{concept.value}</MenuItem>
              ))}
            </Select>
            </FormControl>
          </Grid>
        </Grid>

        <div className="row" style={{ marginBottom: '20px', marginTop: '20px' }}>
          <div className="col">
          <Button variant="contained" onClick={handleQuery} className="query-button">
              <FontAwesomeIcon icon={faArrowRight} /> Query
            </Button>
            <Button variant="contained" color="secondary" onClick={handleReset} className="reset-button">
              <FontAwesomeIcon icon={faTimes} /> Reset
            </Button>
          </div>
          <div className="col-auto">
            <Button variant="contained" className="export-button" onClick={handleExportToExcel}>
              <FontAwesomeIcon icon={faFileExcel} style={{marginRight: '5px' }} /> Export to Excel
            </Button>
          </div>
          <div className="col-auto">
            <Button variant="contained" className="export-button" onClick={handleExportToPDF} style={{ marginLeft: '10px' }}>
              <FontAwesomeIcon icon={faFilePdf} style={{marginRight: '5px' }} /> Export to PDF
            </Button>
          </div>
        </div>

        <div style={{ height: 350, width: '100%' }}>
          <DataGrid
            rows={rowsWithIds}
            columns={columns}
            pageSize={1}
            disableSelectionOnClick
            headerClassName="datagrid-header"
          />
        </div>
      </main>
      {/* End Main Content */}

      {/* Back to Top Button */}
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}

export default BusRevenueReport;
