// middleware/logger.js

import axios from 'axios';

export const logEvent = async (eventType, userId) => {
  const timestamp = new Date().toISOString();
  const ipAddress = await axios.get('https://api.ipify.org?format=json').then(res => res.data.ip);

  const logData = {
    eventType,
    userId,
    timestamp,
    ipAddress,
  };

  // Save the log data to a local storage or send to a server (to be implemented)
  console.log('Log Event:', logData);
};
