import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput,
} from "mdb-react-ui-kit";

import logoImage2 from "../../img/logo.png";
import loginFormImage from "../../img/bus.jpg";
import "../../styles/Report.css";
import { logEvent } from "../../middleware/logger";

// Define the auth utility functions
const auth = {
  password: null,

  setPassword(newPassword) {
    this.password = newPassword;
  },

  getPassword() {
    return this.password;
  },
};

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const signInResponse = await axios.post(
        "https://tns-security-api-uat.thenakedscientists.co.za/api/Authentication/sign-in",
        {
          username,
          password,
        }
      );

      if (
        !signInResponse.data.isSuccessful ||
        signInResponse.data.data.length === 0
      ) {
        setError("Login failed: Invalid username or password.");
        return;
      }

      const { userId } = signInResponse.data.data[0];

      // Log the login attempt
      logEvent("login_attempt", userId);

      const userClaimsResponse = await axios.post(
        "https://tns-security-api-uat.thenakedscientists.co.za/api/Security/get-user-claims",
        {
          userId,
        }
      );

      if (
        !userClaimsResponse.data.isSuccessful ||
        userClaimsResponse.data.data.length === 0
      ) {
        setError("Access denied: Unable to retrieve user claims.");
        return;
      }

      const userClaims = userClaimsResponse.data.data;

      // Check if the user has the required claim
      const hasAccess = userClaims.some(
        (claim) => claim.Role === "MerchantTeller"
      );

      if (hasAccess) {
        auth.setPassword(password);
        navigate("/dashboard");
      } else {
        setError("Access denied: You do not have the required permissions.");
      }
    } catch (error) {
      if (error.response) {
        setError(
          `Login failed: ${
            error.response.data?.message || "An unexpected error occurred."
          }`
        );
      } else if (error.request) {
        setError("Login failed: No response from the server.");
      } else {
        setError("Login failed: An unexpected error occurred.");
      }
    }
  };

  return (
    <MDBContainer className="my-5">
      <MDBCard>
        <MDBRow className="g-0">
          <MDBCol md="6">
            <MDBCardImage
              src={loginFormImage}
              alt="login form"
              className="rounded-start w-100"
            />
          </MDBCol>

          <MDBCol md="6">
            <MDBCardBody className="d-flex flex-column">
              <div className="d-flex flex-row mt-2">
                <MDBIcon
                  fas
                  icon="cubes fa-3x me-3"
                  style={{ color: "#ff6219" }}
                />
                <div className="d-flex justify-content-center w-100">
                  <img
                    src={logoImage2}
                    alt="Logo"
                    style={{ width: "180px", height: "90px" }}
                  />
                </div>
              </div>
              <h5
                className="fw-normal my-4 pb-3"
                style={{ letterSpacing: "1px" }}
              >
                Sign into your account
              </h5>
              <form onSubmit={handleLogin} className="row g-3">
                <div className="col-12 mb-4">
                  <MDBInput
                    label="Email address"
                    id="formControlLg"
                    type="email"
                    size="lg"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </div>
                <div className="col-12 mb-4">
                  <MDBInput
                    label="Password"
                    id="formControlLg"
                    type="password"
                    size="lg"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <MDBBtn className="mb-4 px-5" color="dark" size="lg">
                  Login
                </MDBBtn>
                <a className="small text-muted" href="#!">
                  Forgot password?
                </a>
                <p className="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
                  Don't have an account?{" "}
                  <a href="RegistrationPage" style={{ color: "#393f81" }}>
                    Register here
                  </a>
                </p>
              </form>
              {error && <div className="alert alert-danger mt-3">{error}</div>}
              <div className="d-flex flex-row justify-content-start">
                <a href="#!" className="small text-muted me-1">
                  Terms of use.
                </a>
                <a href="#!" className="small text-muted">
                  Privacy policy
                </a>
              </div>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </MDBContainer>
  );
};

export default LoginPage;
